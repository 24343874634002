<template>
  <div class="main">
    <agent-info-comp></agent-info-comp>

    <agent-sub-title>충전신청</agent-sub-title>

    <div class="gameinfo">
      <div style="width:100%;display: flex;justify-content: center;align-items: center;margin-top: 5px">
        <div style="width: 34%;text-align: center">
          <h5 style="text-align: center">보유금액/은행/예금주</h5>
          <span style="color: #3a8ee6">{{ $store.state.agent.agentInfo.cash|comma }}</span>/
          <span style="color: #3a8ee6">{{ $store.state.agent.agentInfo.bank }}</span>/
          <span style="color: #3a8ee6">{{ $store.state.agent.agentInfo.beneficiary }}</span>
        </div>
      </div>
      <div style="width:100%;display: flex;justify-content: center;align-items: center;margin-top: 5px">
        <div style="width: 34%">
          <h5 style="text-align: center">충전금액</h5>
          <el-input size="mini" v-model="applyCash.amount"></el-input>
        </div>
      </div>
      <div class="item" style="margin-top: 20px">
        <button @click="setCash(10000)">1만</button>
        <button @click="setCash(100000)">10만</button>
        <button @click="setCash(500000)">50만</button>
        <button @click="setCash(1000000)">100만</button>
        <button @click="setCash(5000000)">500만</button>
        <button @click="setCash(10000000)">1000만</button>
        <button @click="resetCash(0)">정정하기</button>
      </div>
      <div style="width:100%;display: flex;justify-content: center;align-items: center;margin-top: 20px">
        <el-button @click="rechargenow()">충전신청</el-button>
      </div>
    </div>

    <div style=" box-sizing: border-box; padding: 10px 0;">
      <div>
        <el-table
            :data="selfExchangeList"
            style="width: 100%"
            max-height="730"
            border>
          <el-table-column
              label="날짜"
              width="130">
            <template slot-scope="scope">
              {{ scope.row.createTime|datef('MM-DD HH:mm:ss') }}
            </template>
          </el-table-column>
          <el-table-column
              label="금액"
              width="100">
            <template slot-scope="scope">
              {{ scope.row.amount|comma }}
            </template>
          </el-table-column>


          <el-table-column
              label="상태"
              width="500">
            <template slot-scope="scope">
              <span v-if="scope.row.status == agentConst.ApplyCash.STATUS_WAITING">미확인</span>
              <span v-if="scope.row.status == agentConst.ApplyCash.STATUS_CHECKING">확인중</span>
              <span v-if="scope.row.status == agentConst.ApplyCash.STATUS_COMPLITE"
                    style="color: deepskyblue">완료</span>
              <span v-if="scope.row.status == agentConst.ApplyCash.STATUS_CANCEL"
                    style="color: red">실패</span>
            </template>
          </el-table-column>

        </el-table>
      </div>
      <div style="margin-top: 20px">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-sizes="[10, 20, 30, 50]"
            :page-size="10"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageTotal">
        </el-pagination>
      </div>
    </div>
  </div>

</template>

<script>

import AgentSubTitle from "@/components/agent/AgentSubTitle";
import {agentMixin} from "@/common/agent/agentMixin";
import {
  agentRechargeCash, getAgentById, selfRechargeHistory
} from "@/network/agent/commonRequest";
import AgentInfoComp from "../../components/agent/AgentInfoComp";
import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "@/store/mutation-types";

export default {
  name: "AgentRechargeCash",
  components: {
    AgentInfoComp,
    AgentSubTitle,
  },
  mixins: [agentMixin],
  data() {
    return {
      applyCash: {amount: null},
      fullscreenLoading: false,
      selfExchangeList: [],
      pageNum: 1,
      pageSize: 10,
      pageTotal: 0,
    }
  },
  methods: {
    setCash(cash) {
      this.applyCash.amount == null ? this.applyCash.amount = 0 : this.applyCash.amount;
      this.applyCash.amount += cash;
    },
    resetCash() {
      this.applyCash.amount = null
    },
    rechargenow() {
      this.$swal({
        title: '신청하시겠습니까?',
        type: 'question',
        confirmButtonText: ' 예 ',
        cancelButtonText: '아니오'
      }).then((res) => {
        this.betInfo = {selectedVals: []}
        if (res.value) {
          this.$store.commit(RECEIVE_SHOW_LOADING)
          agentRechargeCash(this.applyCash).then(res => {
            this.$store.commit(RECEIVE_HIDE_LOADING)
            if (res.data.success) {
              this.$message.success("충전신청이 완료되였습니다");
              this.applyCash = {};
              getAgentById(-1).then(res => {
                this.$store.state.agent.agentInfo = res.data.data;
              })
              this.getSelfRechargeList();
            } else {
              this.$message.error(res.data.msg);
            }
          })
        }
      })
    },
    getSelfRechargeList() {
      selfRechargeHistory(this.pageNum, this.pageSize).then(res => {
        this.pageTotal = res.data.total
        this.selfExchangeList = res.data.data;
      })
    },
    handleSizeChange(val) {
      this.pageNum = 1;
      this.pageSize = val;
      this.getSelfRechargeList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getSelfRechargeList();
    },
  },
  created() {
    this.getSelfRechargeList()
  }
}
</script>

<style scoped>
.table50 {
  border: 2px solid #0c0e0e;
  margin-top: 5px;
  font-weight: 600;
}

.data {
  width: 100%;
  height: calc(100vh - 185px);
  overflow-y: scroll;
  align-items: flex-start;
}

.gameinfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  background-color: #b3d8ff;
  box-sizing: border-box;
  padding: 10px;
}

.item {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.item button {
  background-color: #3a8ee6;
  border: 1px solid #e5e5e5;
  color: #fff9ff;
  padding: 5px;
  margin: 0 1px;
}

.g1 {
  margin-top: 5px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #b3d8ff;
  padding: 5px;
  border-radius: 3px;
}

</style>