/*New Font*/
const MemberLogin = () => import('../views/afront/MemberLogin.vue');

/*관리자*/
const ManagerLogin = () => import('../views/administrator/ManagerLogin');
const MainStatistic = () => import('../views/administrator/MainStatistic');
const MainStatisticSimple = () => import('../views/administrator/MainStatisticSimple');
const ManagerSportsGame = () => import('../views/administrator/ManagerSportsGame');
const ManagerLeisureGame = () => import('../views/administrator/ManagerLeisureGame');
const ManagerUser = () => import('../views/administrator/ManagerUser');
const ManagerSportsBet = () => import('../views/administrator/ManagerSportsBet');
const ManagerLeisureGameBet = () => import('../views/administrator/ManagerLeisureGameBet');
const ManagerCasinoBetXimax = () => import('../views/administrator/ManagerCasinoBetXimax');
const ManagerCasinoBetHonor = () => import('../views/administrator/ManagerCasinoBetHonor');
const ManagerCasinoBetReach = () => import('../views/administrator/ManagerCasinoBetReach');
const ManagerTgame365Bet = () => import('../views/administrator/ManagerTgame365Bet');
const ManagerApplyRecharge = () => import('../views/administrator/ManagerApplyRecharge');
const ManagerApplyExchange = () => import('../views/administrator/ManagerApplyExchange');
const ManagerUserGroup = () => import('../views/administrator/ManagerUserGroup');
const ManagerRank = () => import('../views/administrator/ManagerRank');
const ManagerPartner = () => import('../views/administrator/ManagerPartner');
const ManagerAgent = () => import('../views/administrator/ManagerAgent');
const ManagerRegisterCode = () => import('../views/administrator/ManagerRegisterCode');
const ManagerCustomerCenter = () => import('../views/administrator/ManagerCustomerCenter');
const ManagerLeisureConfig = () => import('../views/administrator/ManagerLeisureConfig');
const ManagerMessage = () => import('../views/administrator/ManagerMessage');
const ManagerBoard = () => import('../views/administrator/ManagerBoard');
const ManagerCashPointLog = () => import('../views/administrator/ManagerCashPointLog');
const ManagerModifiedHistory = () => import('../views/administrator/ManagerModifiedHistory');
const ManagerLoginHistory = () => import('../views/administrator/ManagerLoginHistory');
const ManagerGameType = () => import('../views/administrator/ManagerGameType');
const ManageLeague = () => import('../views/administrator/ManageLeague');
const ManagerLeagueMatching = () => import('../views/administrator/ManagerLeagueMatching');
const ManagerTeamNameMatching = () => import('../views/administrator/ManagerTeamNameMatching');
const ManagerSiteConfig = () => import('../views/administrator/ManagerSiteConfig');
const ManagerCrossRule = () => import('../views/administrator/ManagerCrossRule');
const ManagerCustomerTemplate = () => import('../views/administrator/ManagerCustomerTemplate');
const ManagerIP = () => import('../views/administrator/ManagerIP');
const ManagerPopup = () => import('../views/administrator/ManagerPopup');
const ManagerChangePasswd = () => import('../views/administrator/ManagerChangePasswd');
const ManagerLoginFailHistory = () => import('../views/administrator/ManagerLoginFailHistory');
const ManagerRepeateLoginHistory = () => import('../views/administrator/ManagerRepeateLoginHistory.vue');
const ManagerPhoneCode = () => import('../views/administrator/ManagerPhoneCode');
const ManagerDenied = () => import('../views/administrator/Denied');
const Administrator = () => import('../views/administrator/Administrator');

/*에이전트*/
const AgentLogin = () => import('../views/agent/AgentLogin');
const Agent = () => import('../views/agent/Agent');
const AgentMainStatistic = () => import('../views/agent/AgentMainStatistic');
const AgentMainStatisticSimple = () => import('../views/agent/AgentMainStatisticSimple');
const AgentSubList = () => import('../views/agent/AgentSubList');
const AgentUser = () => import('../views/agent/AgentUser');
const AgentApplyRecharge = () => import('../views/agent/AgentApplyRecharge');
const AgentApplyExchange = () => import('../views/agent/AgentApplyExchange');
const AgentCashPointLog = () => import('../views/agent/AgentCashPointLog');
const AgentSportsBet = () => import('../views/agent/AgentSportsBet');
const AgentLeisureGameBet = () => import('../views/agent/AgentLeisureGameBet');
const AgentCasinoBetHonor = () => import('../views/agent/AgentCasinoBetHonor');
const AgentCasinoBetXimax = () => import('../views/agent/AgentCasinoBetXimax');
const AgentMoveCash = () => import('../views/agent/AgentMoveCash');
const AgentExchangeCash = () => import('../views/agent/AgentExchangeCash');
const AgentRechargeCash = () => import('../views/agent/AgentRechargeCash');
const AgentTgame365Bet = () => import('../views/agent/AgentTgame365Bet');
const AgentUserCashPointLog = () => import('../views/agent/AgentUserCashPointLog');
const AgentSubAgentApplyExchange = () => import('../views/agent/AgentSubAgentApplyExchange');
const AgentCustomerCenter = () => import('../views/agent/AgentCustomerCenter.vue');
const AgentMessage = () => import('../views/agent/AgentMessage.vue');

/*New Front*/

const AFront = () => import('../views/afront/AFront.vue');
const NewMain = () => import('../views/afront/Main.vue');
const Sports = () => import('../views/afront/sports/match/Sports.vue');
const SportsSpecial = () => import('../views/afront/sports/match/SportsSpecial.vue');
const Inplay = () => import('../views/afront/sports/match/Inplay.vue');
const MemberRecharge = () => import('../views/afront/user/Recharge.vue');
const MemberExchange = () => import('../views/afront/user/Exchange.vue');
const MemberCustomerCenter = () => import('../views/afront/user/CustomerCenter.vue');
const MemberMessage = () => import('../views/afront/user/Message.vue');
const MemberCulcheck = () => import('../views/afront/user/Culcheck.vue');
const MemberCoupon = () => import('../views/afront/user/Coupon.vue');
const RefUsers = () => import('../views/afront/user/RefUsers.vue');
const MemberInfo = () => import('../views/afront/user/UserInfo.vue');
const MemberNotice = () => import('../views/afront/notice/Notice.vue');
const MemberEvent = () => import('../views/afront/notice/Event.vue');
const CasinoSlotBetInfo = () => import('../views/afront/casino/CasinoSlotBetInfo.vue');
const CasinoHonor = () => import('../views/afront/casino/CasinoHonor.vue');
const SlotHonor = () => import('../views/afront/casino/SlotHonor.vue');
const SlotHonorList = () => import('../views/afront/casino/SlotHonorList.vue');
const SportsBetHistory = () => import('../views/afront/sports/match/SportsBetHistory.vue');
const SportsResult = () => import('../views/afront/sports/match/SportsResult.vue');
const MiniGame = () => import('../views/afront/leisure/LeisureGame');
const MiniGameBetHistory = () => import('../views/afront/leisure/LeisureBetHistory');
const Tgame365 = () => import('../views/afront/tgame/Tgame365.vue');
const FreeBoard = () => import('../views/afront/notice/FreeBoard.vue');
const FreeBoardDetail = () => import('../views/afront/notice/FreeBoardDetail.vue');

let routes = [
    {
        path: '/',
        redirect: '/front/main'
    },
    {
        path: '/memberlogin',
        name: 'memberlogin',
        component: MemberLogin,
        meta: {
            title: '로그인'
        }
    },
    {
        path: '/front',
        name: 'front',
        component: AFront,
        meta: {
            title: '메인',
        },
        children: [
            {
                path: 'main',
                name: 'main',
                component: NewMain,
                meta: {
                    title: '메인',
                }
            },
            {
                path: 'sports',
                name: 'sports',
                component: Sports,
                meta: {
                    title: '스포츠',
                }
            },
            {
                path: 'sports_special',
                name: 'sports_special',
                component: SportsSpecial,
                meta: {
                    title: '스포츠 스페셜',
                }
            },
            {
                path: 'inplay',
                name: 'inplay',
                component: Inplay,
                meta: {
                    title: '인플레이',
                }
            },
            {
                path: 'recharge',
                name: 'recharge',
                component: MemberRecharge,
                meta: {
                    title: '충전',
                }
            },
            {
                path: 'exchange',
                name: 'exchange',
                component: MemberExchange,
                meta: {
                    title: '환전',
                }
            },
            {
                path: 'customercenter',
                name: 'customercenter',
                component: MemberCustomerCenter,
                meta: {
                    title: '고객센터',
                }
            },
            {
                path: 'message',
                name: 'message',
                component: MemberMessage,
                meta: {
                    title: '쪽지',
                }
            },
            {
                path: 'culcheck',
                name: 'culcheck',
                component: MemberCulcheck,
                meta: {
                    title: '출석체크',
                }
            },
            {
                path: 'coupon',
                name: 'coupon',
                component: MemberCoupon,
                meta: {
                    title: '쿠폰',
                }
            },
            {
                path: 'refusers',
                name: 'refusers',
                component: RefUsers,
                meta: {
                    title: '추천인 현황',
                }
            },
            {
                path: 'userinfo',
                name: 'userinfo',
                component: MemberInfo,
                meta: {
                    title: '회원정보',
                }
            },
            {
                path: 'notice',
                name: 'notice',
                component: MemberNotice,
                meta: {
                    title: '공지사항',
                }
            },
            {
                path: 'event',
                name: 'event',
                component: MemberEvent,
                meta: {
                    title: '이벤트',
                }
            },
            {
                path: 'freeboard',
                name: 'freeboard',
                component: FreeBoard,
                meta: {
                    title: '게시판',
                }
            },
            {
                path: 'freeboarddetail',
                name: 'freeboarddetail',
                component: FreeBoardDetail,
                meta: {
                    title: '게시판',
                }
            },
            {
                path: 'casino',
                name: 'casino',
                component: CasinoHonor,
                meta: {
                    title: '카지노',
                }
            },
            {
                path: 'slot',
                name: 'slot',
                component: SlotHonor,
                meta: {
                    title: '슬롯',
                }
            },
            {
                path: 'slotlist',
                name: 'slotlist',
                component: SlotHonorList,
                meta: {
                    title: '슬롯',
                }
            },
            {
                path: 'casinoslotbetinfo',
                name: 'casinoslotbetinfo',
                component: CasinoSlotBetInfo,
                meta: {
                    title: '카지노베팅내역',
                }
            },
            {
                path: 'sportsbethistory',
                name: 'sportsbethistory',
                component: SportsBetHistory,
                meta: {
                    title: '스포츠베팅내역',
                }
            },
            {
                path: 'sportsresult',
                name: 'sportsresult',
                component: SportsResult,
                meta: {
                    title: '스포츠결과',
                }
            },
            {
                path: 'minigamebethistory',
                name: 'minigamebethistory',
                component: MiniGameBetHistory,
                meta: {
                    title: '미니게임베팅내역',
                }
            },
            {
                path: 'minigame',
                name: 'minigame',
                component: MiniGame,
                children: [
                    {
                        path: '/',
                        redirect: 'eospowerball1m'
                    },
                    {
                        path: 'npowersadali3s',
                        name: 'leisure_detail_npowersadali3s',
                        component: resolve => require(['../views/afront/leisure/child/NamedNewPowerSadali3M'], resolve),
                        meta: {
                            title: 'Named 파워사다리 3분'
                        }
                    },
                    {
                        path: 'npowersadali5s',
                        name: 'leisure_detail_npowersadali5s',
                        component: resolve => require(['../views/afront/leisure/child/NamedNewPowerSadali5M'], resolve),
                        meta: {
                            title: 'Named 파워사다리 5분'
                        }
                    },
                    {
                        path: 'npowerball3s',
                        name: 'leisure_detail_npowerball3s',
                        component: resolve => require(['../views/afront/leisure/child/NamedNewPowerBall3M'], resolve),
                        meta: {
                            title: 'Named 파워볼 3분'
                        }
                    },
                    {
                        path: 'npowerball5s',
                        name: 'leisure_detail_npowerball5s',
                        component: resolve => require(['../views/afront/leisure/child/NamedNewPowerBall5M'], resolve),
                        meta: {
                            title: 'Named 파워볼 5분'
                        }
                    },
                    {
                        path: 'powersadali',
                        name: 'leisure_detail_powersadali',
                        component: resolve => require(['../views/afront/leisure/child/PowerSadali'], resolve),
                        meta: {
                            title: '파워사다리'
                        }
                    },
                    {
                        path: 'speedkino',
                        name: 'leisure_detail_speedkino',
                        component: resolve => require(['../views/afront/leisure/child/SpeedKino'], resolve),
                        meta: {
                            title: '스피드키노'
                        }
                    },
                    {
                        path: 'kinosadali',
                        name: 'leisure_detail_kinosadali',
                        component: resolve => require(['../views/afront/leisure/child/KinoSadali'], resolve),
                        meta: {
                            title: '키노 사다리'
                        }
                    },
                    {
                        path: 'gamemoaace',
                        name: 'leisure_detail_gamemoaace',
                        component: resolve => require(['../views/afront/leisure/child/GameMoaAce'], resolve),
                        meta: {
                            title: 'Ace'
                        }
                    },

                    {
                        path: 'gamemoabubblesdl1',
                        name: 'leisure_detail_gamemoabubblesdl1',
                        component: resolve => require(['../views/afront/leisure/child/GameMoaBubbleSadali1'], resolve),
                        meta: {
                            title: '보글보글 사다리 1분'
                        }
                    },
                    {
                        path: 'gamemoabubblesdl3',
                        name: 'leisure_detail_gamemoabubblesdl3',
                        component: resolve => require(['../views/afront/leisure/child/GameMoaBubbleSadali3'], resolve),
                        meta: {
                            title: '보글보글 사다리 3분'
                        }
                    },
                    {
                        path: 'speedgamepdali',
                        name: 'leisure_detail_speedgamepdali',
                        component: resolve => require(['../views/afront/leisure/child/SpeedGamePDALI'], resolve),
                        meta: {
                            title: '황금돼지 사다리'
                        }
                    },
                    {
                        path: 'gamemoassd1',
                        name: 'leisure_detail_gamemoassd1',
                        component: resolve => require(['../views/afront/leisure/child/GameMoaStartSadali1'], resolve),
                        meta: {
                            title: 'GM별다리 1분'
                        }
                    },
                    {
                        path: 'gamemoassd5',
                        name: 'leisure_detail_gamemoassd5',
                        component: resolve => require(['../views/afront/leisure/child/GameMoaStartSadali5'], resolve),
                        meta: {
                            title: 'GM별다리 5분'
                        }
                    },
                    {
                        path: 'ssd1',
                        name: 'leisure_detail_ssd1',
                        component: resolve => require(['../views/afront/leisure/child/StarSadali1'], resolve),
                        meta: {
                            title: '별다리 1분'
                        }
                    },
                    {
                        path: 'ssd2',
                        name: 'leisure_detail_ssd2',
                        component: resolve => require(['../views/afront/leisure/child/StarSadali2'], resolve),
                        meta: {
                            title: '별다리 2분'
                        }
                    },
                    {
                        path: 'ssd3',
                        name: 'leisure_detail_ssd3',
                        component: resolve => require(['../views/afront/leisure/child/StarSadali3'], resolve),
                        meta: {
                            title: '별다리 3분'
                        }
                    },
                    {
                        path: 'nameddalidali',
                        name: 'leisure_detail_nameddalidali',
                        component: resolve => require(['../views/afront/leisure/child/NamedDalidali'], resolve),
                        meta: {
                            title: '네임드 다리다리'
                        }
                    },
                    {
                        path: 'namedsadali',
                        name: 'leisure_detail_namedsadali',
                        component: resolve => require(['../views/afront/leisure/child/NamedSadali'], resolve),
                        meta: {
                            title: '네임드 사다리'
                        }
                    },
                    {
                        path: 'namedspeedsadali',
                        name: 'leisure_detail_namedspeedsadali',
                        component: resolve => require(['../views/afront/leisure/child/NamedSpeedSadali'], resolve),
                        meta: {
                            title: '네임드 스피드 사다리'
                        }
                    },
                    {
                        path: 'namedsnail',
                        name: 'leisure_detail_namedsnail',
                        component: resolve => require(['../views/afront/leisure/child/NamedSnail'], resolve),
                        meta: {
                            title: '네임드 달팽이'
                        }
                    },
                    {
                        //Bet365SoccerPremiership
                        path: 'bet365_premiership',
                        name: 'leisure_detail_bet365_premiership',
                        component: resolve => require(['../views/afront/leisure/child/Bet365SoccerPremiership'], resolve),
                        meta: {
                            title: 'Bet365 프리미어'
                        }
                    },
                    {
                        //Bet365SoccerSuperLeague
                        path: 'bet365_superleague',
                        name: 'leisure_detail_bet365_superleague',
                        component: resolve => require(['../views/afront/leisure/child/Bet365SoccerSuperLeague'], resolve),
                        meta: {
                            title: 'Bet365 슈퍼리그'
                        }
                    },
                    {
                        //Bet365SoccerEuroCup
                        path: 'bet365_eurocup',
                        name: 'leisure_detail_bet365_eurocup',
                        component: resolve => require(['../views/afront/leisure/child/Bet365SoccerEuroCup'], resolve),
                        meta: {
                            title: 'Bet365 유로컵'
                        }
                    },
                    {
                        //Bet365SoccerWorldCup
                        path: 'bet365_worldcup',
                        name: 'leisure_detail_bet365_worldcup',
                        component: resolve => require(['../views/afront/leisure/child/Bet365SoccerWorldCup'], resolve),
                        meta: {
                            title: 'Bet365 월드컵'
                        }
                    },
                    {
                        //Bet365SoccerWorldCup
                        path: 'bet365_goldenhill',
                        name: 'leisure_detail_bet365_goldenhill',
                        component: resolve => require(['../views/afront/leisure/child/Bet365DogRaceGoldenHill'], resolve),
                        meta: {
                            title: 'Bet365 개경주 골든힐'
                        }
                    },
                    {
                        //Bet365SoccerWorldCup
                        path: 'bet365_hillside',
                        name: 'leisure_detail_bet365_hillside',
                        component: resolve => require(['../views/afront/leisure/child/Bet365DogRaceHillSide'], resolve),
                        meta: {
                            title: 'Bet365 개경주 힐사이드'
                        }
                    },
                    {
                        //Bet365SoccerWorldCup
                        path: 'bet365_horserace',
                        name: 'leisure_detail_horserace',
                        component: resolve => require(['../views/afront/leisure/child/Bet365HorseRace'], resolve),
                        meta: {
                            title: '경마 트렌담 파크'
                        }
                    },
                    {
                        //
                        path: 'bet365_horserace2',
                        name: 'leisure_detail_horserace2',
                        component: resolve => require(['../views/afront/leisure/child/Bet365HorseRace2'], resolve),
                        meta: {
                            title: '경마 브리타니아 웨이'
                        }
                    },
                    {
                        path: 'bet365_horserace3',
                        name: 'leisure_detail_horserace3',
                        component: resolve => require(['../views/afront/leisure/child/Bet365HorseRace3'], resolve),
                        meta: {
                            title: '경마 페스티벌 다운즈'
                        }
                    },
                    {
                        path: 'bet365_horserace4',
                        name: 'leisure_detail_horserace4',
                        component: resolve => require(['../views/afront/leisure/child/Bet365HorseRace4'], resolve),
                        meta: {
                            title: '경마 빅토리아 파크'
                        }
                    },
                    {
                        //Bet365SoccerSuperLeague
                        path: 'bet365_water',
                        name: 'leisure_detail_bet365_warter',
                        component: resolve => require(['../views/afront/leisure/child/Bet365BasketBallWaterLeague'], resolve),
                        meta: {
                            title: 'Bet365 워터포트'
                        }
                    },
                    {
                        //Bet365SoccerSuperLeague
                        path: 'bet365_baker',
                        name: 'leisure_detail_bet365_baker',
                        component: resolve => require(['../views/afront/leisure/child/Bet365BasketBallBakerLeague'], resolve),
                        meta: {
                            title: 'Bet365 워터포트'
                        }
                    },
                    {
                        path: 'powerball',
                        name: 'leisure_detail_powerball',
                        component: resolve => require(['../views/afront/leisure/child/Powerball'], resolve),
                        meta: {
                            title: '파워볼'
                        }
                    },
                    {
                        path: 'tgamepowerball',
                        name: 'leisure_detail_tgamepowerball',
                        component: resolve => require(['../views/afront/leisure/child/Tgame365Powerball'], resolve),
                        meta: {
                            title: '파워볼'
                        }
                    },
                    {
                        //Bet365SoccerWorldCup
                        path: 'powerball_lotto',
                        name: 'leisure_detail_powerball_lotto',
                        component: resolve => require(['../views/afront/leisure/child/PowerballLotto'], resolve),
                        meta: {
                            title: '파워볼 로또'
                        }
                    }
                    ,
                    {
                        //크라운 섯다
                        path: 'sutda',
                        name: 'leisure_detail_sutda',
                        component: resolve => require(['../views/afront/leisure/child/CrownSutda'], resolve),
                        meta: {
                            title: '크라운 섯다'
                        }
                    },
                    {
                        //크라운 나인볼
                        path: 'nineball',
                        name: 'leisure_detail_nineball',
                        component: resolve => require(['../views/afront/leisure/child/CrownNineBall'], resolve),
                        meta: {
                            title: '크라운 섯다'
                        }
                    },
                    {
                        //로투스 바카라
                        path: 'lotus_bakara',
                        name: 'leisure_detail_lotus_bakara',
                        component: resolve => require(['../views/afront/leisure/child/LotusBakara'], resolve),
                        meta: {
                            title: '로투스 바카라'
                        }
                    },
                    {
                        //로투스 바카라
                        path: 'lotus_oddeven',
                        name: 'leisure_detail_lotus_oddeven',
                        component: resolve => require(['../views/afront/leisure/child/LotusOddEven'], resolve),
                        meta: {
                            title: '로투스 홀짝'
                        }
                    },
                    {
                        //로투스 바카라
                        path: 'lotus_dragontiger',
                        name: 'leisure_detail_lotus_dragontiger',
                        component: resolve => require(['../views/afront/leisure/child/LotusDragonTiger'], resolve),
                        meta: {
                            title: '로투스 드래곤타이거'
                        }
                    },
                    {
                        //sky speed 바카라
                        path: 'sky_speed_bakara',
                        name: 'leisure_detail_sky_speed_bakara',
                        component: resolve => require(['../views/afront/leisure/child/SkySpeedBakara'], resolve),
                        meta: {
                            title: '스카이파크 Speed 바카라'
                        }
                    },
                    {
                        //sky 홀짝
                        path: 'sky_oddeven',
                        name: 'leisure_detail_sky_oddeven',
                        component: resolve => require(['../views/afront/leisure/child/SkyOddEven'], resolve),
                        meta: {
                            title: '스카이파크 홀짝'
                        }
                    },
                    {
                        //sky 주사위
                        path: 'sky_dice',
                        name: 'leisure_detail_sky_dice',
                        component: resolve => require(['../views/afront/leisure/child/SkyDice'], resolve),
                        meta: {
                            title: '스카이파크 주사위'
                        }
                    },
                    {
                        //mgm ghfwkr
                        path: 'next_power3m',
                        name: 'leisure_detail_next_power3m',
                        component: resolve => require(['../views/afront/leisure/child/NextPowerBall3M'], resolve),
                        meta: {
                            title: 'Next 파워볼 3분'
                        }
                    },
                    {
                        //mgm ghfwkr
                        path: 'next_ring1m',
                        name: 'leisure_detail_next_ring1m',
                        component: resolve => require(['../views/afront/leisure/child/NextRing1m'], resolve),
                        meta: {
                            title: 'Next 1분링'
                        }
                    },
                    {
                        //mgm ghfwkr
                        path: 'next_bakara',
                        name: 'leisure_detail_next_bakara',
                        component: resolve => require(['../views/afront/leisure/child/NextBaccara'], resolve),
                        meta: {
                            title: 'Next 바카라'
                        }
                    },
                    {
                        //mgm ghfwkr
                        path: 'next_sadali',
                        name: 'leisure_detail_next_sadali',
                        component: resolve => require(['../views/afront/leisure/child/NextSadali'], resolve),
                        meta: {
                            title: 'Next 사다리'
                        }
                    },
                    {
                        //mgm ghfwkr
                        path: 'mgm_bakara',
                        name: 'leisure_detail_mgm_bakara',
                        component: resolve => require(['../views/afront/leisure/child/MgmBakara'], resolve),
                        meta: {
                            title: 'mgm 바카라'
                        }
                    },
                    {
                        //mgm ghfwkr
                        path: 'mgm_gostop',
                        name: 'leisure_detail_mgm_gostop',
                        component: resolve => require(['../views/afront/leisure/child/MgmGostop'], resolve),
                        meta: {
                            title: 'mgm 고스톱'
                        }
                    },
                    {
                        //mgm ghfwkr
                        path: 'mgm_oddeven',
                        name: 'leisure_detail_mgm_oddeven',
                        component: resolve => require(['../views/afront/leisure/child/MgmOddEven'], resolve),
                        meta: {
                            title: 'mgm 홀짝'
                        }
                    },
                    {
                        path: 'eospowerball1m',
                        name: 'leisure_detail_eospowerball1m',
                        component: resolve => require(['../views/afront/leisure/child/EOSPowerBall1M'], resolve),
                        meta: {
                            title: 'EOS 파워볼 1분'
                        }
                    },
                    {
                        //Bet365SoccerWorldCup
                        path: 'eospowerball3m',
                        name: 'leisure_detail_eospowerball3m',
                        component: resolve => require(['../views/afront/leisure/child/EOSPowerBall3M'], resolve),
                        meta: {
                            title: 'EOS 파워볼 3분'
                        }
                    },
                    {
                        //Bet365SoccerWorldCup
                        path: 'eospowerball5m',
                        name: 'leisure_detail_eospowerball5m',
                        component: resolve => require(['../views/afront/leisure/child/EOSPowerBall5M'], resolve),
                        meta: {
                            title: 'EOS 파워볼 5분'
                        }
                    },
                    {
                        //Bet365SoccerWorldCup
                        path: 'suremanpowerball1m',
                        name: 'leisure_detail_suremanpowerball1m',
                        component: resolve => require(['../views/afront/leisure/child/SureManPowerBall1M'], resolve),
                        meta: {
                            title: '슈어맨 파워볼 1분'
                        }
                    },
                    {
                        //Bet365SoccerWorldCup
                        path: 'suremanpowerball2m',
                        name: 'leisure_detail_suremanpowerball2m',
                        component: resolve => require(['../views/afront/leisure/child/SureManPowerBall2M'], resolve),
                        meta: {
                            title: '슈어맨 파워볼 2분'
                        }
                    },
                    {
                        //Bet365SoccerWorldCup
                        path: 'suremanpowerball3m',
                        name: 'leisure_detail_suremanpowerball1m',
                        component: resolve => require(['../views/afront/leisure/child/SureManPowerBall3M'], resolve),
                        meta: {
                            title: '슈어맨 파워볼 3분'
                        }
                    },
                    {
                        //Bet365SoccerWorldCup
                        path: 'suremansadali1m',
                        name: 'leisure_detail_suremansadali1m',
                        component: resolve => require(['../views/afront/leisure/child/SureManSadali1M'], resolve),
                        meta: {
                            title: '슈어맨 사디리 1분'
                        }
                    },
                    {
                        //Bet365SoccerWorldCup
                        path: 'suremansadali2m',
                        name: 'leisure_detail_suremansadali2m',
                        component: resolve => require(['../views/afront/leisure/child/SureManSadali2M'], resolve),
                        meta: {
                            title: '슈어맨 사디리 2분'
                        }
                    },
                    {
                        //Bet365SoccerWorldCup
                        path: 'suremansadali3m',
                        name: 'leisure_detail_suremansadali3m',
                        component: resolve => require(['../views/afront/leisure/child/SureManSadali3M'], resolve),
                        meta: {
                            title: '슈어맨 사디리 3분'
                        }
                    },
                    {
                        //Bet365SoccerWorldCup
                        path: 'hspowerball3m',
                        name: 'leisure_detail_hspowerball3m',
                        component: resolve => require(['../views/afront/leisure/child/HSPowerBall3M'], resolve),
                        meta: {
                            title: '하운슬로 파워볼 3분'
                        }
                    },
                    {
                        //Bet365SoccerWorldCup
                        path: 'hspowerball5m',
                        name: 'leisure_detail_hspowerball5m',
                        component: resolve => require(['../views/afront/leisure/child/HSPowerBall5M'], resolve),
                        meta: {
                            title: '하운슬로 파워볼 5분'
                        }
                    },
                    {
                        //Bet365SoccerWorldCup
                        path: 'pamgamemario',
                        name: 'leisure_detail_pamgamemario',
                        component: resolve => require(['../views/afront/leisure/child/PamGameMario'], resolve),
                        meta: {
                            title: '마리오'
                        }
                    },
                    {
                        //Bet365SoccerWorldCup
                        path: 'pamgameslot',
                        name: 'leisure_detail_pamgameslot',
                        component: resolve => require(['../views/afront/leisure/child/PamGameSlot'], resolve),
                        meta: {
                            title: '슬롯'
                        }
                    },
                    {
                        //Bet365SoccerWorldCup
                        path: 'pamgamedragontiger',
                        name: 'leisure_detail_pamgamedragontiger',
                        component: resolve => require(['../views/afront/leisure/child/PamGameDragonTiger'], resolve),
                        meta: {
                            title: 'Dragon&Tiger'
                        }
                    }


                ],
                meta: {
                    title: '미니게임'
                }
            },
            {
                path: 'tgame',
                name: 'tgame',
                component: Tgame365,
                children: [
                    {
                        path: 'hilow5s',
                        name: 'hilow5s',
                        component: resolve => require(['../views/afront/tgame/child/Tgame365HighLow5S.vue'], resolve),
                        meta: {
                            title: '토큰게임 하이로5초',
                        }
                    },
                    {
                        path: 'hilow10s',
                        name: 'hilow10s',
                        component: resolve => require(['../views/afront/tgame/child/Tgame365HighLow10S.vue'], resolve),
                        meta: {
                            title: '토큰게임 하이로10초',
                        }
                    },
                    {
                        path: 'hilowrullet',
                        name: 'hilowrullet',
                        component: resolve => require(['../views/afront/tgame/child/Tgame365Ruollet.vue'], resolve),
                        meta: {
                            title: '토큰게임 룰렛',
                        }
                    },
                    {
                        path: 'hilowfw',
                        name: 'hilowfw',
                        component: resolve => require(['../views/afront/tgame/child/Tgame365FW.vue'], resolve),
                        meta: {
                            title: '토큰게임 포츈휠',
                        }
                    },
                    {
                        path: 'baccarat',
                        name: 'baccarat',
                        component: resolve => require(['../views/afront/tgame/child/Tgame365Baccarat.vue'], resolve),
                        meta: {
                            title: '토큰게임 바카라',
                        }
                    }
                ]
            },
        ]
    },


    /*에이전트*/
    {
        path: '/partner',
        name: 'partner',
        component: AgentLogin,
        meta: {
            title: '에이전트 - 로그인'
        }
    },
    {
        path: '/agent',
        name: 'agent',
        component: Agent,
        children: [
            {
                path: 'agent_customercenter',
                name: 'agent_customercenter',
                component: AgentCustomerCenter ,
                meta: {
                    title: '에이전트 고객센터',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'agent_message',
                name: 'agent_message',
                component: AgentMessage ,
                meta: {
                    title: '에이전트 쪽지',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'agent_cash_recharge',
                name: 'agent_cash_recharge',
                component: AgentRechargeCash ,
                meta: {
                    title: '에이전트 충전신청',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'agent_cash_exchange',
                name: 'agent_cash_exchange',
                component: AgentExchangeCash,
                meta: {
                    title: '에이전트 환전신청',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'agent_move_cash',
                name: 'agent_move_cash',
                component: AgentMoveCash,
                meta: {
                    title: '에이전트 머니이동',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'agent_casino_honor_bet',
                name: 'agent_casino_honor_bet',
                component: AgentCasinoBetHonor,
                meta: {
                    title: '에이전트 H카지노 베팅내역',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'agent_casino_ximax_bet',
                name: 'agent_casino_ximax_bet',
                component: AgentCasinoBetXimax,
                meta: {
                    title: '에이전트 X카지노 베팅내역',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'agent_leisurebet',
                name: 'agent_leisurebet',
                component: AgentLeisureGameBet,
                meta: {
                    title: '에이전트 미니게임 베팅내역',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'agent_sportsbet',
                name: 'agent_sportsbet',
                component: AgentSportsBet,
                meta: {
                    title: '에이전트 스포츠 베팅내역',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'agent_tgame365_bet',
                name: 'agent_tgame365_bet',
                component: AgentTgame365Bet,
                meta: {
                    title: '에이전트 T365게임 베팅내역',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'agent_logcash_list',
                name: 'agent_logcash_list',
                component: AgentCashPointLog,
                meta: {
                    title: '에이전트 내캐쉬내역',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'agent_user_cashpoint_history',
                name: 'agent_user_cashpoint_history',
                component: AgentUserCashPointLog,
                meta: {
                    title: '회원 머니로그',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'agent_subagent_exchange_list',
                name: 'agent_subagent_exchange_list',
                component: AgentSubAgentApplyExchange,
                meta: {
                    title: '에이전트 하위에이전트출금목록',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'agent_exchange_list',
                name: 'agent_exchange_list',
                component: AgentApplyExchange,
                meta: {
                    title: '에이전트 회원출금목록',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'agent_recharge_list',
                name: 'agent_recharge_list',
                component: AgentApplyRecharge,
                meta: {
                    title: '에이전트 회원입금목록',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'agent_user_list',
                name: 'agent_user_list',
                component: AgentUser,
                meta: {
                    title: '에이전트 회원목록',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'agent_sub_list',
                name: 'agent_sub_list',
                component: AgentSubList,
                meta: {
                    title: '에이전트 리스트',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'agent_main_statistic',
                name: 'agent_main_statistic',
                component: AgentMainStatistic,
                meta: {
                    title: '에이전트 월별 통계',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.5',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'agent_main_statistic_simple',
                name: 'agent_main_statistic_simple',
                component: AgentMainStatisticSimple,
                meta: {
                    title: '에이전트 월별 통계',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.5',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: '/',
                redirect: 'agent_main_statistic_simple'
            },
        ]
    },


    /*관리자*/
    {
        path: '/manager',
        name: 'manager',
        component: ManagerLogin,
        meta: {
            title: '관리자 - 로그인',
            metaTags: [
                {
                    name: 'viewport',
                    content: 'width=1920, initial-scale=0.4',
                },
                // 추가적인 메타 태그들을 여기에 추가
            ],
        }
    },
    {
        path: '/administrator',
        name: 'administrator',
        component: Administrator,
        meta: {
            title: '홈 페이지',
        },
        children: [

            {
                path: 'manager_popup',
                name: 'manager_popup',
                component: ManagerPopup
                ,
                meta: {
                    title: '관리자-팝업 설정',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_phonecode',
                name: 'manager_phonecode',
                component: ManagerPhoneCode
                ,
                meta: {
                    title: '관리자-폰코드 ',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_ipcontainer',
                name: 'manager_ipcontainer',
                component: ManagerIP,
                meta: {
                    title: '관리자-IP 설정',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_custemp',
                name: 'manager_custemp',
                component: ManagerCustomerTemplate,
                meta: {
                    title: '관리자-템플렛 설정',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_crossrule',
                name: 'manager_crossrule',
                component: ManagerCrossRule,
                meta: {
                    title: '관리자-크로스 룰 설정',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_siteconfig',
                name: 'manager_siteconfig',
                component: ManagerSiteConfig,
                meta: {
                    title: '관리자-사이트설정',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_teamnamemaching',
                name: 'manager_teamnamemaching',
                component: ManagerTeamNameMatching,
                meta: {
                    title: '관리자-팀명매칭',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_league',
                name: 'manager_league',
                component: ManageLeague,
                meta: {
                    title: '관리자-리그관리',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_league_matching',
                name: 'manager_league_matching',
                component: ManagerLeagueMatching,
                meta: {
                    title: '관리자-리그매핑관리',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_gametype',
                name: 'manager_gametype',
                component: ManagerGameType,
                meta: {
                    title: '관리자-종목관리',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_loglogin',
                name: 'manager_loglogin',
                component: ManagerLoginHistory,
                meta: {
                    title: '관리자-로그인 로그관리',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_modifiedhistory',
                name: 'manager_modifiedhistory',
                component: ManagerModifiedHistory,
                meta: {
                    title: '관리자-정보변경 로그관리',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_cashpointlog1',
                name: 'manager_cashpointlog1',
                component: ManagerCashPointLog,
                meta: {
                    title: '관리자-머니,포인트 로그관리',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_cashpointlog2',
                name: 'manager_cashpointlog2',
                component: ManagerCashPointLog,
                meta: {
                    title: '관리자-머니,포인트 로그관리',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_board1',
                name: 'manager_board1',
                component: ManagerBoard,
                meta: {
                    title: '관리자-공지,이벤트 관리',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_board2',
                name: 'manager_board2',
                component: ManagerBoard,
                meta: {
                    title: '관리자-공지,이벤트 관리',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_board3',
                name: 'manager_board3',
                component: ManagerBoard,
                meta: {
                    title: '관리자-공지,이벤트 관리',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_board4',
                name: 'manager_board4',
                component: ManagerBoard,
                meta: {
                    title: '관리자-게시판 관리',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_board5',
                name: 'manager_board5',
                component: ManagerBoard,
                meta: {
                    title: '관리자-공지,이벤트 관리',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_message',
                name: 'manager_message',
                component: ManagerMessage,
                meta: {
                    title: '관리자-쪽지 관리',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_leisure_config',
                name: 'manager_leisure_config',
                component: ManagerLeisureConfig,
                meta: {
                    title: '관리자-미니게임설정 관리',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_customer',
                name: 'manager_customer',
                component: ManagerCustomerCenter,
                meta: {
                    title: '관리자-고객센터 관리',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_register_code',
                name: 'manager_register_code',
                component: ManagerRegisterCode,
                meta: {
                    title: '관리자-가입코드 관리',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_partner',
                name: 'manager_partner',
                component: ManagerPartner,
                meta: {
                    title: '관리자-파트너 관리',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_agent',
                name: 'manager_agent',
                component: ManagerAgent,
                meta: {
                    title: '관리자-에이전트 관리',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_rank',
                name: 'manager_rank',
                component: ManagerRank,
                meta: {
                    title: '관리자-레벨관리',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_usergoup',
                name: 'manager_usergoup',
                component: ManagerUserGroup,
                meta: {
                    title: '관리자-그룹관리',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_exchange',
                name: 'manager_exchange',
                component: ManagerApplyExchange,
                meta: {
                    title: '관리자-출금관리',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_tgame365_bet',
                name: 'manager_tgame365_bet',
                component: ManagerTgame365Bet,
                meta: {
                    title: '관리자-토큰게임 베팅내역',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_casino_honor',
                name: 'manager_casino_honor',
                component: ManagerCasinoBetHonor,
                meta: {
                    title: '관리자-Honor카지노베팅내역',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_casino_ximax',
                name: 'manager_casino_ximax',
                component: ManagerCasinoBetXimax,
                meta: {
                    title: '관리자-Ximax카지노베팅내역',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_casino_reach',
                name: 'manager_casino_reach',
                component: ManagerCasinoBetReach,
                meta: {
                    title: '관리자-Reach카지노베팅내역',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_leisuregamebet',
                name: 'manager_leisuregamebet',
                component: ManagerLeisureGameBet,
                meta: {
                    title: '관리자-미니게임 베팅',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_sportsbet',
                name: 'manager_sportsbet',
                component: ManagerSportsBet,
                meta: {
                    title: '관리자-스포츠 베팅',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_user',
                name: 'manager_user',
                component: ManagerUser,
                meta: {
                    title: '관리자-회원정보관리',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_user2',
                name: 'manager_user2',
                component: ManagerUser,
                meta: {
                    title: '관리자-미인증회원',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_user3',
                name: 'manager_user3',
                component: ManagerUser,
                meta: {
                    title: '관리자-인증중회원',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_leisure_game0',
                name: 'manager_leisure_game0',
                component: ManagerLeisureGame,
                meta: {
                    title: '관리자-미니게임',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_leisure_game1',
                name: 'manager_leisure_game1',
                component: ManagerLeisureGame,
                meta: {
                    title: '관리자-미니게임',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_leisure_game2',
                name: 'manager_leisure_game2',
                component: ManagerLeisureGame,
                meta: {
                    title: '관리자-미니게임',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_leisure_game3',
                name: 'manager_leisure_game3',
                component: ManagerLeisureGame,
                meta: {
                    title: '관리자-미니게임',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_leisure_game4',
                name: 'manager_leisure_game4',
                component: ManagerLeisureGame,
                meta: {
                    title: '관리자-미니게임',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_leisure_game5',
                name: 'manager_leisure_game5',
                component: ManagerLeisureGame,
                meta: {
                    title: '관리자-미니게임',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_leisure_game6',
                name: 'manager_leisure_game6',
                component: ManagerLeisureGame,
                meta: {
                    title: '관리자-미니게임',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_leisure_game7',
                name: 'manager_leisure_game7',
                component: ManagerLeisureGame,
                meta: {
                    title: '관리자-미니게임',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_leisure_game8',
                name: 'manager_leisure_game8',
                component: ManagerLeisureGame,
                meta: {
                    title: '관리자-미니게임',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_leisure_game9',
                name: 'manager_leisure_game9',
                component: ManagerLeisureGame,
                meta: {
                    title: '관리자-미니게임',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_leisure_game10',
                name: 'manager_leisure_game10',
                component: ManagerLeisureGame,
                meta: {
                    title: '관리자-미니게임',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_leisure_game11',
                name: 'manager_leisure_game11',
                component: ManagerLeisureGame,
                meta: {
                    title: '관리자-미니게임',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_leisure_game12',
                name: 'manager_leisure_game12',
                component: ManagerLeisureGame,
                meta: {
                    title: '관리자-미니게임',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_leisure_game13',
                name: 'manager_leisure_game13',
                component: ManagerLeisureGame,
                meta: {
                    title: '관리자-미니게임',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_leisure_game14',
                name: 'manager_leisure_game14',
                component: ManagerLeisureGame,
                meta: {
                    title: '관리자-미니게임',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_leisure_game15',
                name: 'manager_leisure_game15',
                component: ManagerLeisureGame,
                meta: {
                    title: '관리자-미니게임',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_leisure_game16',
                name: 'manager_leisure_game16',
                component: ManagerLeisureGame,
                meta: {
                    title: '관리자-미니게임',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_leisure_game17',
                name: 'manager_leisure_game17',
                component: ManagerLeisureGame,
                meta: {
                    title: '관리자-미니게임',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_leisure_game18',
                name: 'manager_leisure_game18',
                component: ManagerLeisureGame,
                meta: {
                    title: '관리자-미니게임',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_leisure_game19',
                name: 'manager_leisure_game19',
                component: ManagerLeisureGame,
                meta: {
                    title: '관리자-미니게임',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_sports',
                name: 'manager_sports',
                component: ManagerSportsGame,
                meta: {
                    title: '관리자-스포츠경기',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_sports_1',
                name: 'manager_sports_1',
                component: ManagerSportsGame,
                meta: {
                    title: '관리자-스포츠경기',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_sports_2',
                name: 'manager_sports_2',
                component: ManagerSportsGame,
                meta: {
                    title: '관리자-스포츠경기',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_sports_3',
                name: 'manager_sports_3',
                component: ManagerSportsGame,
                meta: {
                    title: '관리자-스포츠경기',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_sports_4',
                name: 'manager_sports_4',
                component: ManagerSportsGame,
                meta: {
                    title: '관리자-스포츠경기',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_sports_5',
                name: 'manager_sports_5',
                component: ManagerSportsGame,
                meta: {
                    title: '관리자-스포츠경기',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_sports_6',
                name: 'manager_sports_6',
                component: ManagerSportsGame,
                meta: {
                    title: '관리자-스포츠경기',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_sports_7',
                name: 'manager_sports_7',
                component: ManagerSportsGame,
                meta: {
                    title: '관리자-스포츠경기',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_sports_8',
                name: 'manager_sports_8',
                component: ManagerSportsGame,
                meta: {
                    title: '관리자-스포츠경기',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'main_statistic',
                name: 'main_statistic',
                component: MainStatistic,
                //component: resolve => require(['../views/administrator/MainStatistic'], resolve),
                meta: {
                    title: '관리자-통계표',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'main_statistic_simple',
                name: 'main_statistic_simple',
                component: MainStatisticSimple,
                meta: {
                    title: '관리자-통계표',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_recharge',
                name: 'manager_recharge',
                component: ManagerApplyRecharge,
                meta: {
                    title: '관리자-입금관리',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_changepasswd',
                name: 'manager_changepasswd',
                component: ManagerChangePasswd,
                meta: {
                    title: '관리자-비밀번호 변경',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_logloginfail',
                name: 'manager_logloginfail',
                component: ManagerLoginFailHistory,
                meta: {
                    title: '관리자-로그인실패회원',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_log_repeate_login',
                name: 'manager_log_repeate_login',
                component: ManagerRepeateLoginHistory,
                meta: {
                    title: '관리자-중복로그인',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: 'manager_denied',
                name: 'manager_denied',
                component: ManagerDenied,
                meta: {
                    title: '관리자-권한없음',
                    metaTags: [
                        {
                            name: 'viewport',
                            content: 'width=1920, initial-scale=0.4',
                        },
                        // 추가적인 메타 태그들을 여기에 추가
                    ],
                }
            },
            {
                path: '/',
                //redirect: 'main_statistic'
                redirect: 'main_statistic_simple'
            },

        ]
    },


]

export default routes