import { render, staticRenderFns } from "./InplayGameTypeSelector.vue?vue&type=template&id=3bcc886c&scoped=true"
import script from "./InplayGameTypeSelector.vue?vue&type=script&lang=js"
export * from "./InplayGameTypeSelector.vue?vue&type=script&lang=js"
import style0 from "./InplayGameTypeSelector.vue?vue&type=style&index=0&id=3bcc886c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bcc886c",
  null
  
)

export default component.exports