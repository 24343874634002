<template>

  <div class="content">
    <div class="main_img">
      <warning-for-sample></warning-for-sample>
      <swiper :options="swiperOption">
        <swiper-slide>
          <img src="../../assets/images/main/ptn/main01.jpg" alt="" style="width: 100%">
        </swiper-slide>
<!--        <swiper-slide>-->
<!--          <img src="../../assets/images/main/ptn/main02.jpg" alt="" style="width: 100%">-->
<!--        </swiper-slide>-->
<!--        <swiper-slide>-->
<!--          <img src="../../assets/images/main/ptn/main03.jpg" alt="" style="width: 100%">-->
<!--        </swiper-slide>-->
<!--        <div class="swiper-pagination" slot="pagination"></div>-->
      </swiper>
    </div>
    <!--2줄 이미지-->
    <div class="sub_pc_img">
      <div class="item">
        <router-link to="/front/sports" tag="span" style="cursor: pointer">
          <img src="../../assets/images/main/ptn/bn01.jpg" alt="">
        </router-link>
      </div>
      <div class="item">
        <router-link :to="{path:'/front/casino'}" tag="span" style="cursor: pointer">
          <img src="../../assets/images/main/ptn/bn02.jpg" alt="">
        </router-link>
      </div>
      <div class="item">
        <router-link :to="{path:'/front/slot'}" tag="span" style="cursor: pointer">
          <img src="../../assets/images/main/ptn/bn03.jpg" alt="">
        </router-link>
      </div>
      <div class="item">
        <router-link :to="{path:'/front/minigame/eospowerball1m'}" tag="span" style="cursor: pointer">
          <img src="../../assets/images/main/ptn/bn04.jpg" alt="">
        </router-link>
      </div>
      <div class="item">
        <router-link to="/front/event" tag="span">
          <img src="../../assets/images/main/ptn/bn05.jpg" alt="">
        </router-link>
      </div>
    </div>

    <div class="sub_mobile_menus">
      <router-link tag="div" to="/front/sports" class="mtime">
        <img src="../../assets/images/bg/mobile_menu/ptn/sports.jpg" alt="">
      </router-link>
<!--      <router-link tag="div" to="/front/inplay" class="mtime">-->
<!--        <img src="../../assets/images/bg/mobile_menu/ptn/inplay.jpg" alt="">-->
<!--      </router-link>-->
      <router-link tag="div" to="/front/sports_special" class="mtime">
        <img src="../../assets/images/bg/mobile_menu/ptn/special.jpg" alt="">
      </router-link>

      <router-link to="/front/casino"  tag="div" class="mtime">
        <img src="../../assets/images/bg/mobile_menu/ptn/casino.jpg" alt="">
      </router-link>
      <router-link to="/front/slot" tag="div" class="mtime">
        <img src="../../assets/images/bg/mobile_menu/ptn/slot.jpg" alt="">
      </router-link>
      <router-link tag="div" to="/front/minigame/eospowerball1m" class="mtime">
        <img src="../../assets/images/bg/mobile_menu/ptn/minigame.jpg" alt="">
      </router-link>
      <router-link tag="div" to="/front/minigame/bet365_superleague" class="mtime">
        <img src="../../assets/images/bg/mobile_menu/ptn/bet365.jpg" alt="">
      </router-link>
<!--      <router-link tag="div" to="/front/tgame/hilow10s" class="mtime">-->
<!--        <img src="../../assets/images/bg/mobile_menu/ptn/tgame.jpg" alt="">-->
<!--      </router-link>-->
      <router-link tag="div" to="/front/coupon" class="mtime">
        <img src="../../assets/images/bg/mobile_menu/ptn/coupon.jpg" alt="">
      </router-link>

      <router-link tag="div" to="/front/culcheck" class="mtime">
        <img src="../../assets/images/bg/mobile_menu/ptn/culcheck.jpg" alt="">
      </router-link>

      <router-link tag="div" to="/front/notice" class="mtime">
        <img src="../../assets/images/bg/mobile_menu/ptn/notice.jpg" alt="">
      </router-link>

      <router-link tag="div" to="/front/event" class="mtime">
        <img src="../../assets/images/bg/mobile_menu/ptn/event.jpg" alt="">
      </router-link>

      <router-link tag="div" to="/front/refusers" class="mtime">
        <img src="../../assets/images/bg/mobile_menu/ptn/recmember.jpg" alt="">
      </router-link>

      <router-link tag="div" to="/front/message" class="mtime">
        <img src="../../assets/images/bg/mobile_menu/ptn/message.jpg" alt="">
      </router-link>
      <router-link tag="div" to="/front/sportsresult" class="mtime">
        <img src="../../assets/images/bg/mobile_menu/ptn/gameresult.jpg" alt="">
      </router-link>
      <router-link tag="div" to="/front/sportsbethistory" class="mtime">
        <img src="../../assets/images/bg/mobile_menu/ptn/betinfo.jpg" alt="">
      </router-link>
      <router-link tag="div" to="" class="mtime">
        <img src="../../assets/images/bg/mobile_menu/ptn/commingsoon.jpg" alt="">
      </router-link>
      <router-link tag="div" to="" class="mtime">
        <img src="../../assets/images/bg/mobile_menu/ptn/commingsoon.jpg" alt="">
      </router-link>
    </div>


    <!--메인 공지,이벤트-->
    <div class="noticeandresult">
      <div class="nt">
        <div class="nt_title">
          공지사항
          <router-link tag="span" to="/front/notice"
                       style="float: right;font-size: 12px;color: #fff9ff;cursor: pointer">+전체보기
          </router-link>
        </div>
        <div class="cont_warp">
          <router-link tag="div" :to="{path: '/front/notice', query: {id:item.id,t: new Date().getTime()}}"
                       v-for="item in noticeList"
                       class="nt_cont">
            <!--                            <span> <img src="../../assets/images/icon/common/notice.png"></span> {{item.title}}-->
            <span style="color: #2e9ade">(공지)</span> {{item.title}}
          </router-link>
        </div>

      </div>
      <div class="nt">
        <div class="nt_title">
          이벤트
          <router-link tag="span" to="/front/event"
                       style="float: right;font-size: 12px;color: #fff9ff;cursor: pointer">+전체보기
          </router-link>
        </div>
        <div class="cont_warp">
          <router-link tag="div" :to="{path: '/front/event', query: {id:item.id,t: new Date().getTime()}}"
                       v-for="item in eventList"
                       class="nt_cont">
            <!--                            <span> <img src="../../assets/images/icon/common/event.png"></span> {{item.title}}-->
            <span style="color: #2e9ade">(이벤트)</span> {{item.title}}
          </router-link>
        </div>

      </div>
    </div>
  </div>

</template>

<script>

import Left from "@/views/afront/Left.vue";
import Header from "@/views/afront/Header.vue";
import Right from "@/views/afront/Right.vue";
import Footer from "@/views/afront/Footer.vue";
import MyUncheckedMessageComp from "@/views/afront/notice/MyUncheckedMessageComp.vue";
import PopupComp from "@/views/afront/notice/PopupComp.vue";
import {getEvent, getNoticeList} from "@/network/userRequest";
import {getRecentlyEndGames, getRecentlyGames} from "@/network/sportsRequest";
import sportsConst from "@/common/sportsConst";
import inplayConst from "@/common/inplayConst";
import WarningForSample from "@/views/afront/zero/WarningForSample.vue";

export default {
  name: "Main",
  components: {WarningForSample, PopupComp, MyUncheckedMessageComp, Footer, Right, Header, Left},
  data() {
    return {
      sportsConst,
      inplayConst,
      noticeList: [],
      eventList: [],
      soccer: [],
      base: [],
      basket: [],
      hokey: [],
      esports: [],
      vol: [],
      swiperOption: {
        pagination: {el: '.swiper-pagination'},
        autoplay: {},
        disableOnInteraction: false,
        delay: 2000,
      },
    }
  },
  methods: {

  },
  created() {
    // getRecentlyGames().then(res => {
    //   if (res.data.success) {
    //     let recentlryGames = res.data.data
    //     this.soccer = recentlryGames.soccer
    //     this.basket = recentlryGames.bascket
    //     this.base = recentlryGames.base
    //     this.vol = recentlryGames.vol
    //     this.hokey = recentlryGames.hockey
    //     this.esports = recentlryGames.esports
    //   }
    // })
    // getRecentlyEndGames().then(res => {
    //   //this.endgameList = res.data.data
    // })
    getNoticeList(100).then(res => {
      this.noticeList = res.data.data
    })
    getEvent(1, 100).then(res => {
      this.eventList = res.data.data
    })
    this.$store.state.userinsertagentcode='';
    this.$store.state.ischeckedagentcode=false;
  }
}
</script>

<style scoped>
@import url("../../assets/css/front/afrontmain.css");
</style>