var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_HS_POWERBALL_3M
                 || _vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_HS_POWERBALL_5M)?_c('el-select',{staticStyle:{"width":"180px","margin-right":"5px"},attrs:{"size":"mini","placeholder":""},on:{"change":_vm.changeLeisureKind},model:{value:(_vm.kind),callback:function ($$v) {_vm.kind=$$v},expression:"kind"}},[_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_HS_POWERBALL_3M,"label":"하운슬로 파워볼 3분"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_HS_POWERBALL_5M,"label":"하운슬로 파워볼 5분"}})],1):_vm._e(),(_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_EOS_POWERBALL_1M
                 || _vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_EOS_POWERBALL_3M
                 || _vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_EOS_POWERBALL_5M
                 || _vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_POWERSADALI
                 || _vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_KINOSADALI
                  || _vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_SPEEDKINO)?_c('el-select',{staticStyle:{"width":"180px","margin-right":"5px"},attrs:{"size":"mini","placeholder":""},on:{"change":_vm.changeLeisureKind},model:{value:(_vm.kind),callback:function ($$v) {_vm.kind=$$v},expression:"kind"}},[_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_EOS_POWERBALL_1M,"label":"EOS 파워볼 1분"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_EOS_POWERBALL_3M,"label":"EOS 파워볼 3분"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_EOS_POWERBALL_5M,"label":"EOS 파워볼 5분"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_POWERSADALI,"label":"엔트리 파워사다리"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_SPEEDKINO,"label":"엔트리 스피드 키노"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_KINOSADALI,"label":"엔트리 키노 사다리"}})],1):_vm._e(),(_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_BET365_SOCCER_EUROCUP
                 ||_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_BET365_SOCCER_SUPERLEAGUE
                 ||_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_BET365_SOCCER_PREMIERSHIP
                 ||_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_BET365_SOCCER_WORLDCUP)?_c('el-select',{staticStyle:{"width":"180px","margin-right":"5px"},attrs:{"size":"mini","placeholder":""},on:{"change":_vm.changeLeisureKind},model:{value:(_vm.kind),callback:function ($$v) {_vm.kind=$$v},expression:"kind"}},[_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_BET365_SOCCER_PREMIERSHIP,"label":"가상축구 - 프리미어"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_BET365_SOCCER_EUROCUP,"label":"가상축구 - 유로컵"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_BET365_SOCCER_SUPERLEAGUE,"label":"가상축구 - 슈퍼리그"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_BET365_SOCCER_WORLDCUP,"label":"가상축구 - 월드컵"}})],1):_vm._e(),(_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_BET365_BASKETBALL_WARTER
                 ||_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_BET365_BASKETBALL_BAKER)?_c('el-select',{staticStyle:{"width":"180px","margin-right":"5px"},attrs:{"size":"mini","placeholder":""},on:{"change":_vm.changeLeisureKind},model:{value:(_vm.kind),callback:function ($$v) {_vm.kind=$$v},expression:"kind"}},[_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_BET365_BASKETBALL_WARTER,"label":"가상농구 - 워터포드"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_BET365_BASKETBALL_BAKER,"label":"가상농구 - 베이커"}})],1):_vm._e(),(_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_BET365_DOGRACE_GOLDENHILLPARK
                 ||_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_BET365_DOGRACE_HILLSIDEPARK)?_c('el-select',{staticStyle:{"width":"180px","margin-right":"5px"},attrs:{"size":"mini","placeholder":""},on:{"change":_vm.changeLeisureKind},model:{value:(_vm.kind),callback:function ($$v) {_vm.kind=$$v},expression:"kind"}},[_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_BET365_DOGRACE_GOLDENHILLPARK,"label":"가상개경주 - 골든힐"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_BET365_DOGRACE_HILLSIDEPARK,"label":"가상개경주 - 힐사이드"}})],1):_vm._e(),(_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_BET365_HORCERACE_2
                 ||_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_BET365_HORCERACE_3
                  ||_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_BET365_HORCERACE_4)?_c('el-select',{staticStyle:{"width":"180px","margin-right":"5px"},attrs:{"size":"mini","placeholder":""},on:{"change":_vm.changeLeisureKind},model:{value:(_vm.kind),callback:function ($$v) {_vm.kind=$$v},expression:"kind"}},[_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_BET365_HORCERACE_2,"label":"가상경마 - 브리타니아"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_BET365_HORCERACE_3,"label":"가상경마 - 페스티발"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_BET365_HORCERACE_4,"label":"가상경마 - 픽토리아"}})],1):_vm._e(),(_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_STAR_SD_1
                 ||_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_STAR_SD_2
                  ||_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_STAR_SD_3)?_c('el-select',{staticStyle:{"width":"180px","margin-right":"5px"},attrs:{"size":"mini","placeholder":""},on:{"change":_vm.changeLeisureKind},model:{value:(_vm.kind),callback:function ($$v) {_vm.kind=$$v},expression:"kind"}},[_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_STAR_SD_1,"label":"보스코어 별다리 1분"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_STAR_SD_2,"label":"보스코어 별다리 2분"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_STAR_SD_3,"label":"보스코어 별다리 3분"}})],1):_vm._e(),(_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_SUTDA
                 ||_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_NINEBALL)?_c('el-select',{staticStyle:{"width":"180px","margin-right":"5px"},attrs:{"size":"mini","placeholder":""},on:{"change":_vm.changeLeisureKind},model:{value:(_vm.kind),callback:function ($$v) {_vm.kind=$$v},expression:"kind"}},[_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_SUTDA,"label":"크라운 섯다"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_NINEBALL,"label":"크라운 나인볼"}})],1):_vm._e(),(_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_SKY_SPEED_BAKARA
                 ||_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_SKY_ODDEVEN
                 ||_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_SKY_DICE)?_c('el-select',{staticStyle:{"width":"180px","margin-right":"5px"},attrs:{"size":"mini","placeholder":""},on:{"change":_vm.changeLeisureKind},model:{value:(_vm.kind),callback:function ($$v) {_vm.kind=$$v},expression:"kind"}},[_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_SKY_SPEED_BAKARA,"label":"스카이파크 바카라"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_SKY_ODDEVEN,"label":"스카이파크 홀짝"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_SKY_DICE,"label":"스카이파크 주사위"}})],1):_vm._e(),(_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_BAKARA_LOTUS_1
                 ||_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_ODDEVEN_LOTUS
                 ||_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_LOTUS_DRAGONTIGER)?_c('el-select',{staticStyle:{"width":"180px","margin-right":"5px"},attrs:{"size":"mini","placeholder":""},on:{"change":_vm.changeLeisureKind},model:{value:(_vm.kind),callback:function ($$v) {_vm.kind=$$v},expression:"kind"}},[_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_BAKARA_LOTUS_1,"label":"로투스 바카라"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_ODDEVEN_LOTUS,"label":"로투스 홀짝"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_LOTUS_DRAGONTIGER,"label":"로투스 드래곤타이거"}})],1):_vm._e(),(_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_GAMEMOA_BUBBLE_SADALI_1M
                  ||_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_GAMEMOA_BUBBLE_SADALI_3M)?_c('el-select',{staticStyle:{"width":"180px","margin-right":"5px"},attrs:{"size":"mini","placeholder":""},on:{"change":_vm.changeLeisureKind},model:{value:(_vm.kind),callback:function ($$v) {_vm.kind=$$v},expression:"kind"}},[_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_GAMEMOA_BUBBLE_SADALI_1M,"label":"보글사다리 1분"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_GAMEMOA_BUBBLE_SADALI_3M,"label":"보글사다리 3분"}})],1):_vm._e(),(_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_GAMEMOA_STAR_SADALI_1M
                  ||_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_GAMEMOA_STAR_SADALI_5M)?_c('el-select',{staticStyle:{"width":"180px","margin-right":"5px"},attrs:{"size":"mini","placeholder":""},on:{"change":_vm.changeLeisureKind},model:{value:(_vm.kind),callback:function ($$v) {_vm.kind=$$v},expression:"kind"}},[_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_GAMEMOA_STAR_SADALI_1M,"label":"별다리 1분"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_GAMEMOA_STAR_SADALI_5M,"label":"별다리 5분"}})],1):_vm._e(),(_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_NAMED_NEWPOWERBALL_3S
                  ||_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_NAMED_NEWPOWERBALL_5S
                  ||_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_NAMED_NEWPOWER_SADALI_3S
                  ||_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_NAMED_NEWPOWER_SADALI_5S)?_c('el-select',{staticStyle:{"width":"180px","margin-right":"5px"},attrs:{"size":"mini","placeholder":""},on:{"change":_vm.changeLeisureKind},model:{value:(_vm.kind),callback:function ($$v) {_vm.kind=$$v},expression:"kind"}},[_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_NAMED_NEWPOWERBALL_3S,"label":"네임드 뉴 파워볼 3분"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_NAMED_NEWPOWERBALL_5S,"label":"네임드 뉴 파워볼 5분"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_NAMED_NEWPOWER_SADALI_3S,"label":"네임드 뉴 파워사다리 3분"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_NAMED_NEWPOWER_SADALI_5S,"label":"네임드 뉴 파워사다리 5분"}})],1):_vm._e(),(_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_NEXT_BACCARA
                  ||_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_NEXT_SADALI
                  ||_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_NEXT_POWERBALL
                  ||_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_NEXT_RING1M)?_c('el-select',{staticStyle:{"width":"180px","margin-right":"5px"},attrs:{"size":"mini","placeholder":""},on:{"change":_vm.changeLeisureKind},model:{value:(_vm.kind),callback:function ($$v) {_vm.kind=$$v},expression:"kind"}},[_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_NEXT_SADALI,"label":"NEXT 사다리"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_NEXT_BACCARA,"label":"NEXT 바카라"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_NEXT_POWERBALL,"label":"NEXT 파워볼"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_NEXT_RING1M,"label":"NEXT 1분링"}})],1):_vm._e(),(_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_SUREMAN_SADALI_1M
                  ||_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_SUREMAN_SADALI_2M
                  ||_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_SUREMAN_SADALI_3M
                  ||_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_SUREMAN_POWERBALL_1M
                  ||_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_SUREMAN_POWERBALL_2M
                  ||_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_SUREMAN_POWERBALL_3M)?_c('el-select',{staticStyle:{"width":"180px","margin-right":"5px"},attrs:{"size":"mini","placeholder":""},on:{"change":_vm.changeLeisureKind},model:{value:(_vm.kind),callback:function ($$v) {_vm.kind=$$v},expression:"kind"}},[_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_SUREMAN_POWERBALL_1M,"label":"슈어맨 파워볼 1분"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_SUREMAN_POWERBALL_2M,"label":"슈어맨 파워볼 2분"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_SUREMAN_POWERBALL_3M,"label":"슈어맨 파워볼 3분"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_SUREMAN_SADALI_1M,"label":"슈어맨 사다리 1분"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_SUREMAN_SADALI_2M,"label":"슈어맨 사다리 2분"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_SUREMAN_SADALI_3M,"label":"슈어맨 사다리 3분"}})],1):_vm._e(),(_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_PAMGAME_MARIO
                  ||_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_PAMGAME_SLOT
                  ||_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_PAMGAME_DRAGONTIGER)?_c('el-select',{staticStyle:{"width":"180px","margin-right":"5px"},attrs:{"size":"mini","placeholder":""},on:{"change":_vm.changeLeisureKind},model:{value:(_vm.kind),callback:function ($$v) {_vm.kind=$$v},expression:"kind"}},[_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_PAMGAME_MARIO,"label":"마리오"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_PAMGAME_SLOT,"label":"슬롯"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_PAMGAME_DRAGONTIGER,"label":"드래곤타이거"}})],1):_vm._e(),(_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_MGM_BAKARA
                 ||_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_MGM_ODDEVEN)?_c('el-select',{staticStyle:{"width":"180px","margin-right":"5px"},attrs:{"size":"mini","placeholder":""},on:{"change":_vm.changeLeisureKind},model:{value:(_vm.kind),callback:function ($$v) {_vm.kind=$$v},expression:"kind"}},[_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_MGM_BAKARA,"label":"MGM 바카라"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_MGM_ODDEVEN,"label":"MGM 홀짝"}})],1):_vm._e(),(_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_DONGHANG_POWERBALL_RANDOM
                 ||_vm.kind == _vm.managerConst.LeisureGame.LEISURE_KIND_DONGHANG_POWERSADALI)?_c('el-select',{staticStyle:{"width":"180px","margin-right":"5px"},attrs:{"size":"mini","placeholder":""},on:{"change":_vm.changeLeisureKind},model:{value:(_vm.kind),callback:function ($$v) {_vm.kind=$$v},expression:"kind"}},[_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_DONGHANG_POWERBALL_RANDOM,"label":"동행파워볼(랜덤)"}}),_c('el-option',{attrs:{"value":_vm.managerConst.LeisureGame.LEISURE_KIND_DONGHANG_POWERSADALI,"label":"동행파워사다리"}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }