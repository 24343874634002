import { render, staticRenderFns } from "./Bet365DogRaceGoldenHill.vue?vue&type=template&id=3c85acd7&scoped=true"
import script from "./Bet365DogRaceGoldenHill.vue?vue&type=script&lang=js"
export * from "./Bet365DogRaceGoldenHill.vue?vue&type=script&lang=js"
import style0 from "./Bet365DogRaceGoldenHill.vue?vue&type=style&index=0&id=3c85acd7&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c85acd7",
  null
  
)

export default component.exports