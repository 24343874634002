<template>
  <div
      style="width: 100%;height: 40px;color: #3c3cfc;display: flex;align-items: center;justify-content: center;text-align: center">
    <div style="width: 120px">
      <i class="fa fa-user-circle" style="font-weight: bold;font-size: 14px">
        {{ $store.state.agent.agentInfo.nickname }}</i>
    </div>
    <div style="width: 100px">
      <i class="fa fa-won" style="font-weight: bold;font-size: 14px">
        {{ $store.state.agent.agentInfo.cash|comma }}</i>
    </div>
    <div style="width: 180px">
      <i class="fa fa-database" style="font-weight: bold;font-size: 14px;color: #5b0e0e">
        {{ $store.state.agent.agentInfo.point|comma }}</i>
      <button type="button" @click="changePoint" style="margin-left: 5px">전환</button>
    </div>

    <div style="width: 180px">
      <i class="fa fa-circle-o-notch" style="font-weight: bold;font-size: 14px">입출손익:
        {{ $store.state.agent.agentInfo.rechargeExchangeProfit }}%</i>
    </div>
    <div style="width: 160px">
      <i class="fa fa-circle-o-notch" style="font-weight: bold;font-size: 14px">스포츠:
        {{ $store.state.agent.agentInfo.sportsBetRollingProfit }}%</i>
    </div>
    <div style="width: 160px">
      <i class="fa fa-circle-o-notch" style="font-weight: bold;font-size: 14px">미니게임:
        {{ $store.state.agent.agentInfo.leisureBetRollingProfit }}%</i>
    </div>
    <div style="width: 160px">
      <i class="fa fa-circle-o-notch" style="font-weight: bold;font-size: 14px">카지노:
        {{ $store.state.agent.agentInfo.casinoBetRollingProfit }}%</i>
    </div>
    <div style="width: 160px">
      <i class="fa fa-circle-o-notch" style="font-weight: bold;font-size: 14px">슬롯:
        {{ $store.state.agent.agentInfo.slotBetRollingProfit }}%</i>
    </div>
  </div>
</template>

<script>
import {agentChangePoint, getAgentById} from "@/network/agent/commonRequest";
import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "@/store/mutation-types";

export default {
  name: "AgentInfoComp",
  methods: {
    changePoint() {
      this.$swal({
        title: '포인트 전환하시겠습니까?',
        type: 'question',
        confirmButtonText: ' 예 ',
        cancelButtonText: '아니오'
      }).then((res) => {
        this.betInfo = {selectedVals: []}
        if (res.value) {
          this.$store.commit(RECEIVE_SHOW_LOADING)
          agentChangePoint().then(res => {
            this.$store.commit(RECEIVE_HIDE_LOADING)
            if (res.data.success) {
              this.$message.success("전환성공");
              getAgentById(-1).then(res => {
                this.$store.state.agent.agentInfo = res.data.data;
              })
            } else {
              this.$message.error(res.data.msg);
            }
          })
        }
      })
    }
  }

}
</script>

<style scoped>

</style>