import { render, staticRenderFns } from "./InplayInfo.vue?vue&type=template&id=53379dc3&scoped=true"
import script from "./InplayInfo.vue?vue&type=script&lang=js"
export * from "./InplayInfo.vue?vue&type=script&lang=js"
import style0 from "./InplayInfo.vue?vue&type=style&index=0&id=53379dc3&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53379dc3",
  null
  
)

export default component.exports