import managerConst from "./agentConst";
import agentConst from "./agentConst";
import {getAgentUncheckedMessageCount} from "@/network/agent/commonRequest";

export const agentMixin = {
    data() {
        return {
            agentConst: agentConst,
            startTime: '',
            endTime: '',
            smonth: '',
            selectedPartnerId: null,
        }
    },
    methods:{
        setPartnerId(partnerId){
            this.selectedPartnerId = partnerId
        },
        setStartDate(sdate){
            this.startDate = sdate
        },
        setEndDate(sdate){
            this.endDate = sdate
        },
    },
    created() {
        getAgentUncheckedMessageCount().then(res=>{
            if (res.data.success) {
                this.$store.state.agent.uncheckedmessagecount = res.data.data;
                if(this.$store.state.agent.uncheckedmessagecount > 0){
                    this.$message({
                        showClose: false,
                        duration: 1500,
                        message: '미확인 쪽지가 있습니다. 확인 해주세요',
                        type: 'info'
                    });
                }
            } else{
                this.$store.state.agent.uncheckedmessagecount = 0;
            }
        })
    }
}
