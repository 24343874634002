<template>
  <div class="content">
    <sub-title>충전</sub-title>
    <div class="reg_box">
            <warning-for-sample></warning-for-sample>
      <div class="items l">
        <div class="itm title">
          <div class="t1">충전요청</div>
          <div class="t2"></div>
        </div>
        <div class="itm">
          <div class="t1">보유머니</div>
          <div class="t2">
                          <span class="tit">
                              {{ $store.state.userInfo.cash|comma }}
                          </span>

          </div>
        </div>
        <div class="itm">
          <div class="t1">입금계좌</div>
          <div class="t2">
                          <span class="tit" style="font-size: 11px">
                            입금계좌를 [고객센터]메뉴에서 알려드립니다
                          </span>
            <button class="askacno" @click="questioAcNo">입금계좌요청</button>
          </div>
        </div>

        <div class="itm">
          <div class="t1">보너스선택</div>
          <div class="t2">
                          <span class="tit" style="font-size: 11px">
                            베팅타입에 적합한 보너스를 선택하세요
                          </span>
            <div class="bonuspanel">
              <div class="bs1" style="">
                <input type="radio" name="bonusin" id="bonusin0"
                       :value="sportsConst.APPLY_BONUS_NOT"
                       v-model="applyCash.bonusin" style="width: 5rem">
                <label for="bonusin0" style="color: red;">보너스 미지급</label>
              </div>
              <div class="rollpercent">롤링100%</div>
            </div>

            <div class="bonuspanel">
              <div class="bs1" style="">
                <input type="radio" name="bonusin" id="bonusin1"
                       :value="sportsConst.APPLY_BONUS_YES1"
                       v-model="applyCash.bonusin" style="width: 5rem">
                <label for="bonusin1" style="color: #fff9ff;">스포츠</label>
              </div>
              <div class="rollpercent">롤링100%</div>
            </div>

            <div class="bonuspanel">
              <div class="bs1" style="">
                <input type="radio" name="bonusin" id="bonusin2"
                       :value="sportsConst.APPLY_BONUS_YES2"
                       v-model="applyCash.bonusin" style="width: 5rem">
                <label for="bonusin2" style="color: #ff00ff;">미니게임</label>
              </div>
              <div class="rollpercent">롤링300%</div>
            </div>

            <div class="bonuspanel">
              <div class="bs1" style="">
                <input type="radio" name="bonusin" id="bonusin3"
                       :value="sportsConst.APPLY_BONUS_YES3"
                       v-model="applyCash.bonusin" style="width: 5rem">
                <label for="bonusin3" style="color: #f1ae17;">카지노</label>
              </div>
              <div class="rollpercent">롤링300%</div>
            </div>
            <div class="bonuspanel">
              <div class="bs1" style="">
                <input type="radio" name="bonusin" id="bonusin4"
                       :value="sportsConst.APPLY_BONUS_YES4"
                       v-model="applyCash.bonusin" style="width: 5rem">
                <label for="bonusin4" style="color: #f1ae17;">슬롯</label>
              </div>
              <div class="rollpercent">롤링300%</div>
            </div>
          </div>
        </div>

        <div class="itm">
          <div class="t1">충전금액</div>
          <div class="t2">

            <div style="display: flex;justify-content: flex-start;align-items: center;padding: 5px 0">
              <input type="text" v-model="applyAmount" class="re_input" placeholder="충전금액"
                     @input="setComma">
              <button type="button" class="reset" @click="changeMoney(0)">정정하기
              </button>
            </div>
            <div class="addcashbuttons"
                 style="display: flex; justify-content: flex-start;width: 100%;padding: 3px 0;flex-wrap: wrap">
              <button type="button" @click="changeMoney(10000)">
                1만
              </button>
              <button type="button" @click="changeMoney(50000)">
                5만
              </button>
              <button type="button" @click="changeMoney(100000)">
                10만
              </button>
              <button type="button" @click="changeMoney(500000)">
                50만
              </button>
              <button type="button" @click="changeMoney(1000000)">
                100만
              </button>
              <button type="button" @click="changeMoney(5000000)">
                500만
              </button>
            </div>

          </div>
        </div>

        <div class="itm">
          <div class="t1">입금자</div>
          <div class="t2">
                          <span class="tit" style="font-size: 11px">
                           이체시 반드시 회원님 성함으로 이체 바랍니다
                          </span>
            <span style="margin-top: 5px;color: red">{{ $store.state.userInfo.beneficiary }}</span>
          </div>
        </div>

        <div class="itm">
          <div class="t1"></div>
          <div class="t2">
            <button class="askacno" @click="recharge(0)">충전요청</button>
          </div>
        </div>


      </div>

      <div class="items r">
        <div class="itm title">
          <div class="t1">충전안내</div>
          <div class="t2"></div>
        </div>
        <div class="itm infotext">
          <div v-html="infotext"></div>
        </div>
        <div class="re_history">
          <table class="utable" cellpadding="0" cellspacing="0" border="0">
            <colgroup>
              <col width="27%">
              <col width="27%">
              <col width="27%">
              <col width="15%">
            </colgroup>
            <thead>
            <tr>
              <th>일자</th>
              <th>금액</th>
              <th @click="refreshData()" style="cursor: pointer">상태 <i class="fa fa-refresh"
                                                                       aria-hidden="true"></i>
              </th>
              <th>-</th>
            </tr>
            <tr v-for="(item,indx) in rechargeList" :key="indx">
              <td>{{ item.createTime|datef('MM/DD HH:mm') }}</td>
              <td>{{ item.amount|comma }}</td>
              <td>
                <span v-if="item.status === sportsConst.APPLY_STATUS_WAITING">미확인</span>
                <span v-if="item.status === sportsConst.APPLY_STATUS_CHECKING">확인중</span>
                <span v-if="item.status === sportsConst.APPLY_STATUS_COMPLITE" style="color: #35c82d">완료</span>
                <span v-if="item.status === sportsConst.APPLY_STATUS_CANCEL"
                      style="color: red">실패</span>
              </td>
              <td>
                  <span
                      style="display: inline-block;width: 60px; height: 20px; line-height: 20px;text-align: center;cursor: pointer;color: red"
                      v-if="item.status !== sportsConst.APPLY_STATUS_WAITING && item.status
                        !== sportsConst.APPLY_STATUS_CHECKING" @click="deleteById(item.id)">
                     <i class="fa fa-close"></i> 삭제
                  </span>

              </td>
            </tr>
            </thead>
            <tbody>
            </tbody>
          </table>
        </div>

      </div>
    </div>

  </div>
</template>


<script>

import {
  changeCoin2Cash,
  deleteApply,
  getApplyCashList, getCoinableStatus, getCoinSiteLoginUrl, getinfotext,
  getTodayRechargeStatus,
  rechargeCash,
  saveQuestion
} from "@/network/userRequest";

import {
  RECEIVE_HIDE_LOADING,
  RECEIVE_SHOW_LOADING,
} from "@/store/mutation-types";
import {postionMixin} from "@/common/mixin";
import sportsConst from "@/common/sportsConst";
import SubTitle from "@/views/afront/zero/SubTitle.vue";
import WarningForSample from "@/views/afront/zero/WarningForSample.vue";

export default {
  name: "Recharge",
  mixins: [postionMixin],
  components: {
    WarningForSample,
    SubTitle

  },
  data() {
    return {
      sportsConst,
      infotext: null,
      applyCash: {
        amount: 0,
        bonusin: sportsConst.APPLY_BONUS_NOT,
        consiterecharge: 0,
      },
      applyAmount: '',
      search: {applyType: sportsConst.APPLY_TYPE_RECHARGE},
      rechargeList: [],
      isSampleSite: false,
      casinocash: 0,
      position: "입금",
      b0: null,
      b1: null,
      b2: null,
      b3: null,
      b4: null,
      todaystatus: 0,
      coinable: 0,
    }
  },
  methods: {
    setComma() {
      if (this.applyAmount == '') {
        this.applyAmount = ''
      } else {
        this.applyAmount = this.$root.$options.filters.comma(this.applyAmount)
      }
    },
    changeMoney(cash) {
      if (cash === 0) {
        this.applyAmount = '';
      } else {
        let c = this.applyAmount == '' ? 0 : this.applyAmount.replaceAll(',', '')
        this.applyAmount = this.$root.$options.filters.comma(parseInt(c) + parseInt(cash));

      }
    },
    gotocoinsite() {
      this.$store.commit(RECEIVE_SHOW_LOADING)
      getCoinSiteLoginUrl().then(res => {
        this.$store.commit(RECEIVE_HIDE_LOADING)
        if (res.data.success) {
          let url = res.data.data;
          if (null != url) {
            window.open(url, '_blank');
          }
        } else {
          this.$swal({
            title: res.data.msg,
            type: 'error',
            confirmButtonText: '확인',
            showCancelButton: false,
            showConfirmButton: true
          })
        }

      })
    },
    changeCoin2SiteCash() {
      if (this.applyCash.bonusin === sportsConst.APPLY_BONUS_NOSET) {
        this.$swal({
          title: '보너스 동의를 선택하세요',
          type: 'error',
          confirmButtonText: '확인',
          showCancelButton: false,
          showConfirmButton: true
        })
        return false
      }
      this.$store.commit(RECEIVE_SHOW_LOADING)
      changeCoin2Cash(this.applyCash).then(res => {
        this.$store.commit(RECEIVE_HIDE_LOADING)
        if (res.data.success) {
          this.$swal({
            title: '전환성공',
            type: 'success',
            confirmButtonText: '확인',
            showCancelButton: false,
            showConfirmButton: true
          })
          this.$store.dispatch('actionUserInfo')
        } else {
          this.$swal({
            title: res.data.msg,
            type: 'error',
            confirmButtonText: '확인',
            showCancelButton: false,
            showConfirmButton: true
          })
        }
      })
    },

    recharge(consiterecharge) {

      this.applyCash.amount = this.applyAmount.replaceAll(',', '');

      this.applyCash.consiterecharge = consiterecharge;
      if (this.applyCash.bonusin === -1) {
        this.$swal({
          title: '베팅타입에 적합한 보너스를 선택하세요',
          type: 'error',
          confirmButtonText: '확인',
          showCancelButton: false,
          showConfirmButton: true
        })
        return false
      }

      this.$swal({
        title: this.applyCash.amount + '원 충전신청 하시겠습니까?',
        type: 'question',
        confirmButtonText: ' 예 ',
        cancelButtonText: '아니오'
      }).then((res) => {
        this.betInfo = {selectedVals: []}
        if (res.value) {
          this.$store.commit(RECEIVE_SHOW_LOADING)
          rechargeCash(this.applyCash).then(res => {
            if (res.data.success) {
              this.$swal({
                title: '충전신청성공',
                type: 'success',
                confirmButtonText: '확인',
                showCancelButton: false,
                showConfirmButton: true
              })
              this.applyCash.bonusin = sportsConst.APPLY_BONUS_NOT
              this.applyCash.amount = 0
              this.applyCash.consiterecharge = 0;
              this.applyAmount = ''
              this.initRechargeList()
              // if (this.coinable == sportsConst.YES) {
              //   this.$store.commit(RECEIVE_SHOW_LOADING)
              //   getCoinSiteLoginUrl().then(res => {
              //     let url = res.data.data;
              //     console.log(url)
              //     if (null != url) {
              //       window.open(url,'_blank');
              //     }
              //     this.$store.commit(RECEIVE_HIDE_LOADING)
              //   })
              // }
            } else {
              this.$swal({
                title: res.data.msg,
                type: 'error',
                confirmButtonText: '확인',
                showCancelButton: false,
                showConfirmButton: true
              })
            }
            this.$store.commit(RECEIVE_HIDE_LOADING)
          })
        }
      })
    },
    initRechargeList() {
      this.$store.commit(RECEIVE_SHOW_LOADING)
      getApplyCashList(this.search).then(res => {
        if (res.data.success) {
          this.rechargeList = res.data.data
        }
        this.$store.commit(RECEIVE_HIDE_LOADING)
      })
    },
    refreshData() {
      this.initRechargeList()
      this.$bus.$emit('applyRequestSuccess')
    },
    deleteById(id) {
      this.$swal({
        title: '삭제하시겠습니까?',
        type: 'question',
        confirmButtonText: ' 예 ',
        cancelButtonText: '아니오'
      }).then((res) => {
        if (res.value) {
          deleteApply(id).then(res => {
            if (res.data.success) {
              this.initRechargeList()
            } else {
              this.$swal({
                title: res.data.msg,
                type: 'error',
                confirmButtonText: '확인',
                showCancelButton: false,
                showConfirmButton: true
              })
            }
          })
        }
      })
    },
    questioAcNo() {
      this.$store.commit(RECEIVE_SHOW_LOADING)
      let content = '계좌번호 문의합니다'
      saveQuestion({'content': content, 'type': sportsConst.CUSTOMER_TYPE_BANK_CARD}).then(res => {
        this.$store.commit(RECEIVE_HIDE_LOADING)
        if (res.data.success) {
          this.content = ''
          this.$swal({
            title: '계좌번호 문의글 작성이 완료되였습니다',
            type: 'success',
            confirmButtonText: '확인',
            showCancelButton: false,
            showConfirmButton: true
          })
          this.$router.push('/customer_center')

        } else {
          this.$swal({
            title: res.data.msg,
            type: 'error',
            confirmButtonText: '확인',
            showCancelButton: false,
            showConfirmButton: true
          })
        }
      })
    },
    getTodayRechargeStatus() {
      getTodayRechargeStatus().then(res => {
        this.todaystatus = res.data.data
        try {
          if (this.todaystatus == sportsConst.RECHARGE_FIRSTBONUS) {
            let b = this.$store.state.userInfo.rank.rechargebonus1.split(",")
            this.b1 = '(첫충 ' + b[0] + '%)'
            this.b2 = '(첫충 ' + b[1] + '%)'
            this.b3 = '(첫충 ' + b[2] + '%)'
            this.b4 = '(첫충 ' + b[3] + '%)'
            this.b0 = '(첫충 0%)'
          }
          if (this.todaystatus == sportsConst.RECHARGE_DAYBONUS) {
            let b = this.$store.state.userInfo.rank.rechargebonus2.split(",")
            this.b1 = '(매충 ' + b[0] + '%)'
            this.b2 = '(매충 ' + b[1] + '%)'
            this.b3 = '(매충 ' + b[2] + '%)'
            this.b4 = '(매충 ' + b[3] + '%)'
            this.b0 = '(매충 0%)'
          }
          if (this.todaystatus == sportsConst.RECHARGE_NOBONUS) {
            this.applyCash.bonusin = sportsConst.APPLY_BONUS_NOT
          }
        } catch (e) {

        }

      })
    },
    getInfoText() {
      getinfotext().then(res => {
        this.infotext = res.data.data.rechargeText
      })
    }
  },
  created() {
    this.getInfoText()
    this.initRechargeList()
    this.getTodayRechargeStatus()

    getCoinableStatus().then(res => {
      this.coinable = res.data.data;
    })

  },
  mounted() {

  },
  watch: {
    "applyCash.bonusin": {
      handler(newVal) {
      },
      immediate: true,
      deep: false
    },
  }
}
</script>

<style scoped>
@import url("../../../assets/css/front/afrontapply.css");
</style>