import { render, staticRenderFns } from "./NamedSnail.vue?vue&type=template&id=944e1b96&scoped=true"
import script from "./NamedSnail.vue?vue&type=script&lang=js"
export * from "./NamedSnail.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "944e1b96",
  null
  
)

export default component.exports