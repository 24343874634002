<template>
  <div style="width: 100%">
    <div style="width: 100%;" v-for="(item,idex) in list" :key="item.id">
      <div style="" v-if="item.parentId == agentConst.Parnter.PATNER_GENERALAGENT_NOID"></div>
      <div class="agentline">
        <div style="width: 4%;text-align: center;color: #c6c6c6">
          [{{ item.id }}]
        </div>
        <div style="width: 4%">
          <span v-if="item.status == agentConst.ENABLE">[정상]</span>
          <span style="color: red" v-if="item.status == agentConst.DISABLE">[블럭]</span>

        </div>

        <div style="width: 5%">
          <span>회원[{{ item.userCount }}]</span>
        </div>

        <div style="width: 5%">
          블럭<span style="color: red">[{{ item.userCountBlock }}]</span>
        </div>
        <div style="width: 10%">
          머니<i class="fa fa-won"></i><span style="color: #e7e17c">: {{item.cash|comma}} 원</span><br/>
          포인트<i class="fa fa-database"></i><span style="color: #f1efc2">: {{item.point|comma}} P</span>
        </div>

        <div style="width:43%">
          <span v-if="item.parentId != agentConst.Parnter.PATNER_GENERALAGENT_NOID">|</span>
          <span v-for="index in item.level"
                v-if="item.parentId != agentConst.Parnter.PATNER_GENERALAGENT_NOID">---</span>
          <span style="color: #dd7f7f;font-size: 11px;padding: 0 5px">lv[{{ item.level }}]</span>
          <span class="badge badge-warning" style="color: #143771">{{ item.nickname }}</span>

          <span style="color: #9b9999">({{ item.account }})</span>
          <i class="fa fa-hand-o-right" style="padding: 0 5px"></i>


          <span style="color: skyblue;font-size: 11px">입출손익(<span
              style="color: #fff9ff">{{ item.rechargeExchangeProfit }}</span>)%</span>,
          <span style="color: skyblue;font-size: 11px">카지노(<span
              style="color: #fff9ff">{{ item.casinoBetRollingProfit }}</span>)%</span>,
          <span style="color: orange;font-size: 11px">슬롯(<span
              style="color: #fff9ff">{{ item.slotBetRollingProfit }}</span>)%</span>,
          <span style="color: orange;font-size: 11px">미니게임(<span style="color: #fff9ff">{{item.leisureBetRollingProfit}}</span>)%</span>,
          <span style="color: skyblue;font-size: 11px">스포츠(<span
              style="color: #fff9ff">{{ item.sportsBetRollingProfit }}</span>)%</span>


        </div>


        <div style="width: 30%;display: flex;align-content: center;justify-content: center;flex-wrap: nowrap">
          <el-button type="danger" size="mini" @click="addPartner(item.id,agentConst.MODE_EDIT)"><i
              class="fa fa-pencil-square"></i> 총판수정
          </el-button>
          <el-button type="primary" size="mini" @click="editPartner(item.id,agentConst.MODE_SAVE)"><i
              class="fa fa-plus-square"></i> 하위총판추가
          </el-button>
          <el-button type="primary" size="mini" @click="showPorfit(item)"><i
              class="fa fa-plus-square"></i> 정산보기
          </el-button>
        </div>
      </div>
      <div v-if="item.isshowprofit" style="background-color: #545454;padding: 5px 0">
        <span style="color:#d8f84a;">본회원입금:{{ item.currentAgentRecharegeAmountTotal|comma }}</span> |
        <span style="color:#d8f84a;">본회원출금:-{{ item.currentAgentExchangeAmountTotal|comma }}</span> |
        <span style="color:#ffffff;font-weight: bold">  <i
            class="fa fa-won"></i>정산:{{ item.ownUserProfit|comma }}</span>
      </div>
      <div v-if="item.isshowprofit" style="background-color: #545454;padding: 5px 0">
        <span style="color:#c8ec29;">하부입금:{{ item.subAgentRecharegeAmountTotal|comma }}</span> |
        <span style="color:#c8ec29;">하부출금:-{{ item.subAgentExchangeAmountTotal|comma }}</span> |
        <span style="color:#c8ec29;font-weight: bold">하부정산:{{ item.subUserProfit|comma }}</span>
      </div>
      <div v-if="item.isshowprofit" style="background-color: #545454;padding: 5px 0">
        <span style="color: #a0b4fc"
              v-if="item.givebackdate">최근정산일:{{ item.givebackdate|datef('MM-DD HH:mm') }} | </span>
        <span style="color: #f5efef">   <i
            class="fa fa-won"></i>총정산:{{ (item.ownUserProfit + item.subUserProfit)|comma }}</span>
      </div>
      <agent-recursion-comp2 v-if="item.subAgentList" :list="item.subAgentList"
                             :isshowprofit="isshowprofit"></agent-recursion-comp2>
    </div>
  </div>

</template>

<script>
import Vue from "vue";
import AgentEditToast from "./toast/AgentEditToast";
import {agentMixin} from "../../common/agent/agentMixin";
import {Loading} from "element-ui";
import {showprofit} from "../../network/agent/commonRequest";
import agentConst from "../../common/agent/agentConst";

Vue.prototype.$agentEditToast = AgentEditToast

export default {
  name: "AgentRecursionComp2",
  mixins: [agentMixin],
  props: {
    list: {
      type: Array,
      default() {
        return []
      }
    },
    isshowprofit: {
      type: Boolean,
      default() {
        return false
      }
    }

  },
  data() {
    return {
      agent: {},
      editParnterId: 0,
    }
  },
  methods: {
    showPorfit(agent) {
      let loadingInstance = Loading.service({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      showprofit(agent.id).then(res => {
        loadingInstance.close();
        let data = res.data.data;
        this.$set(agent, 'isshowprofit', agentConst.YES);
        this.$set(agent, 'currentAgentExchangeAmountTotal', data.currentAgentExchangeAmountTotal);
        this.$set(agent, 'currentAgentRecharegeAmountTotal', data.currentAgentRecharegeAmountTotal);
        this.$set(agent, 'currentAgentRecharegeAmountTotal', data.currentAgentRecharegeAmountTotal);
        this.$set(agent, 'subAgentExchangeAmountTotal', data.subAgentExchangeAmountTotal);
        this.$set(agent, 'subAgentRecharegeAmountTotal', data.subAgentRecharegeAmountTotal);
        this.$set(agent, 'ownUserProfit', data.ownUserProfit);
        this.$set(agent, 'subUserProfit', data.subUserProfit);
        this.$set(agent, 'givebackdate', data.givebackdate);
      })
    },
    editPartner(id, mode) {
      this.$agentEditToast({'agentId': id, 'mode': mode})
    },
    addPartner(parentId, mode) {
      this.$agentEditToast({'agentId': parentId, 'mode': mode})
    },
  }
}
</script>

<style scoped>
.agentline {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  height: 70px;
  width: 100%;
  background-color: #171717;
  color: #fff9ff;
  border-bottom: 1px solid #505050
}
</style>