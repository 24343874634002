import { render, staticRenderFns } from "./Coupon.vue?vue&type=template&id=158e057c&scoped=true"
import script from "./Coupon.vue?vue&type=script&lang=js"
export * from "./Coupon.vue?vue&type=script&lang=js"
import style0 from "./Coupon.vue?vue&type=style&index=0&id=158e057c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "158e057c",
  null
  
)

export default component.exports