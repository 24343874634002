import { render, staticRenderFns } from "./ManagerAgent.vue?vue&type=template&id=587d5dcd&scoped=true"
import script from "./ManagerAgent.vue?vue&type=script&lang=js"
export * from "./ManagerAgent.vue?vue&type=script&lang=js"
import style0 from "./ManagerAgent.vue?vue&type=style&index=0&id=587d5dcd&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "587d5dcd",
  null
  
)

export default component.exports